<template>
  <div></div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      specialEventUserGiftResults: [],
    };
  },
  computed: {
  },
  created() {
    let eventBadgeElement = document.getElementById("event-badge-generator");
    let data = JSON.parse(eventBadgeElement.getAttribute('data'));
    console.error(data)
    // let valentineTopUserIds = Object.keys(data.specialEventUserGiftResults)
    this.specialEventUserGiftResults = data.specialEventUserGiftResults
    this.distributeValentineBadge();
  },
  methods: {
    distributeValentineBadge: function(){
      const self = this
      let badgeResults = []
      function splitArray(array, part) {
        var tmp = [];
        for(var i = 0; i < array.length; i += part) {
          tmp.push(array.slice(i, i + part));
        }
          return tmp;
      }
      badgeResults = splitArray(this.specialEventUserGiftResults, 2)
      // console.log(badgeResults)
      badgeResults.forEach(function(result){

        let userCardElements = document.getElementsByClassName(`js-user-id--${result[0]}`)

        for (const element of userCardElements) {
          let badgeElement = self.createValentineBadgeElement(result[1])
          element.appendChild(badgeElement);
        }
      })
    },
    createValentineBadgeElement: function(image){
      let valentineBadgeElement = document.createElement("div");
      valentineBadgeElement.setAttribute('class', 'user-card__spring-festival-event-badge');
      let valentineBadgeImgElement = document.createElement("img");

      valentineBadgeImgElement.setAttribute('src', image);
      
      valentineBadgeElement.appendChild(valentineBadgeImgElement);
      return valentineBadgeElement
    }
  }
}
</script>
